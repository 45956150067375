/* Copyright (C) 2017-2022 Greenbone AG
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
import React from 'react';

import styled from 'styled-components';

import GSA_VERSION from 'version';

import _ from 'gmp/locale';

import {isDefined} from 'gmp/utils/identity';

import HelpIcon from 'web/components/icon/helpicon';

import Img from 'web/components/img/img';

import Layout from 'web/components/layout/layout';
import PageTitle from 'web/components/layout/pagetitle';

import BlankLink from 'web/components/link/blanklink';

import Section from 'web/components/section/section';

import PropTypes from 'web/utils/proptypes';
import withGmp from 'web/utils/withGmp';

const StyledLayout = styled(Layout)`
  margin: 0 auto;
  max-width: 680px;
`;

const DivP = styled.div`
  margin-bottom: 10px;
`;

const About = ({gmp}) => (
  <React.Fragment>
    <PageTitle title={_('About Greenbone Enterprise Appliance')} />
    <Layout flex="column">
      <Section img={<HelpIcon size="large" />} title={_('About Greenbone Enterprise Appliance')}>
        <StyledLayout flex="column" align="center">
          <Img src="greenbone_banner.png" alt="Greenbone Enterprise Appliance" width="100%" />
          <h1>Greenbone Enterprise Appliance</h1>
          <h3>
            {isDefined(gmp.settings.vendorVersion)
              ? gmp.settings.vendorVersion
              : _('Version {{version}}', {version: GSA_VERSION})}
          </h3>
          <DivP>
            {_('The Greenbone Enterprise is a vulnerability ' +
               'management solution that seamlessly and transparently ' +
               'integrates into your security and GRC strategy, providing ' +
               'Vulnerability Assessment, Vulnerability Intelligence and ' +
               'Threat Management capabilities.',
            )}
          </DivP>
          <DivP>
            {_(
              'Greenbone Enterprise Appliance adds various smart features and forms a powerful tool ' +
              'to manage and maintain a high resilience level of the IT ' +
              'infrastructures.'
            )}
          </DivP>
          <DivP>
            {_(
              'This web application uses cookies to store session information' +
              '. The cookies are not stored on the server side hard disk ' +
              'and not submitted anywhere. They are lost when the session ' +
              'is closed or expired. The cookies are stored temporarily in ' +
              'your browser as well where you can examine the content.'
            )}
          </DivP>
          <DivP>
            {_('Please visit the ')}
            <BlankLink to="https://docs.greenbone.net/">
              Greenbone TechDoc Portal
            </BlankLink>
            {_(' where an online version of the comprehensive user manual ' +
                'is provided, accompanied with training videos and other ' +
                'documentation.'
            )}
          </DivP>
          <DivP>
            {_('If you have questions, proposals, or problems, please report ' +
                'them via the ')}
                <BlankLink to="https://support.greenbone.net/">
                  Greenbone Enterprise Support Portal
                </BlankLink>.
          </DivP>
          <DivP>
            {_('The users and developers meet at the public ')}
            <BlankLink to="https://community.greenbone.net/">
              Community Portal
            </BlankLink>.
          </DivP>
          <DivP>
            Copyright 2009-2022 by&nbsp;
            <BlankLink to="https://www.greenbone.net/">
              Greenbone AG
            </BlankLink>
          </DivP>
          <DivP>
            {_('The Greenbone Enterprise combines the Greenbone ' +
                'OS and Greenbone Enterprise Feed. See the '
            )}
            <BlankLink to="https://www.greenbone.net/en/license-information/">
              {_('License')}
            </BlankLink>
            {_(' information.')}
          </DivP>
        </StyledLayout>
      </Section>
    </Layout>
  </React.Fragment>
);

About.propTypes = {
  gmp: PropTypes.gmp.isRequired,
};

export default withGmp(About);

// vim: set ts=2 sw=2 tw=80:
